import React from 'react'
import { Container, Row, Col, Nav, Button } from 'react-bootstrap'
import { Link } from '@reach/router'
import Layout from '../components/Layaout'


 
const Ir = () => {




return (

<Layout>
<Container fluid>

   <Row>

      <Col xs={2} id="sidebar-wraper">
      
      <Nav className="col-lg-12 d-none d-md-block sticky-top sidebar mt-3" >
      <Link  to="/app/registro">
                         <Button >Leer informe completo</Button>
             </Link>
         <p className="sidebarTitle text-muted">Tabla de contenido</p>
             
         <Nav.Item>
             
             <Nav.Link href="#contexto"  >Introducción</Nav.Link>
             
         </Nav.Item>
         <Nav.Item>
             <Nav.Link eventKey="disabled" disabled> Punto de partida</Nav.Link>
         </Nav.Item>
         <Nav.Item>
             <Nav.Link eventKey="disabled" disabled> Conclusiones</Nav.Link>
         </Nav.Item>
         <Nav.Item>
             <Nav.Link eventKey="disabled" disabled> Fuentes</Nav.Link>
         </Nav.Item>
         <Nav.Item >
             <Nav.Link  eventKey="disabled" disabled>
             Tablas y graficos
             </Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link eventKey="disabled" disabled > Precios residencias</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link eventKey="disabled" disabled>S.mínimo mensual</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link eventKey="disabled" disabled>Precios y S.mínimos</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link eventKey="disabled" disabled>Relación precios S.mínimo</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link eventKey="disabled" disabled>Relación precios S.medio</Nav.Link>
         </Nav.Item>
         <Nav.Item>
         <Nav.Link eventKey="disabled" disabled>S.minímo / S.medio</Nav.Link>
         </Nav.Item>
         <Nav.Item >
         <Nav.Link eventKey="disabled" disabled>Precio real vs realativo</Nav.Link>
         </Nav.Item>
                
         </Nav>
         
      </Col>
      <Col xl={10}>
      
         <h1 className="mt-3">Índice ir</h1>
         <p>Por: <a href="https://www.linkedin.com/in/josep-de-mart%C3%AD-vall%C3%A9s-84062836/?originalSubdomain=es">Josep de Martì</a>,  Septiembre 2021</p>
         <h2 className="text-muted">Comparación de precios entre residencias de mayores en varios países europeos</h2>
         <div className="s9-widget-wrapper"></div>
         <Row>
         <Col md={5} className="mt-3">
            <iframe width="342" height="517" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQSbSXFN_PehMrXfookpowtw678CSBXcUmlV3ncRCecI44hbU8xqaJ7KTwOsQkKzA/pubchart?oid=1336167915&amp;format=interactive"></iframe>
            </Col>
            <Col md={5} className="mt-3">
               <ul>
                  <li>
                     Los precios de las residencias en España, entre los más bajos de Europa, tanto en términos absolutos como
                     relativos.
                  </li>
                  <li>
                     Las residencias más caras de Europa en términos absolutos están en Noruega, Suiza y Suecia.
                  </li>
                  <li>
                     Las residencias más caras en términos relativos en Holanda, Noruega y Suecia.
                  </li>
                  
               </ul>
               
            </Col>
            
            
         </Row>
         <h4 className="mt-3" id="contexto">Introducción</h4>
            <p>Aunque el fenómeno del progresivo envejecimiento de la población es común en todos los países de nuestro
               entorno, la forma en que se afronta la dependencia que le acompaña y la prestación de servicios,
               especialmente
               los sustitutorios del hogar, es muy variada, no sólo si se hacen comparaciones internacionales sino, incluso
               dentro
               de zonas geográficas de un mismo país.
            </p>
            <p>La Covid-19 y su efecto letal en personas mayores que vivían en residencias, sobre todo en los primeros
               meses de la pandemia,
               está haciendo que se plantee en casi todo el mundo la necesidad de hacer evolucionar los modelos actuales de
               residencias para
               lo que se buscan aquellos que han podido ser más resilientes o, por algún motivo han mostrado mejores
               resultados para intentar
               utilizarlos como modelos de buenas prácticas.

            </p>
            <p>
               El problema al hacer comparaciones es que existe siempre un factor económico a tener en cuenta que también
               es heterogéneo.
               En cada país las residencias tienen un coste y un precio diferente por lo que intentar trasladar soluciones
               intentadas de un país
               a otro puede resultar fuera de lugar si no se tiene en cuenta la diferencia real de costes económicos.

            </p>
            <p>
               Resultaría por eso interesante contar con una herramienta que permitiese comparar de forma coherente los
               precios de residencias
               en diferentes países. Para hacerlo se podrían tener en cuenta normativas arquitectónicas, ratios de personal
               y condiciones
               salariales; o podría plantearse la pregunta “¿cuánto esfuerzo económico debe asumir alguien que vaya a pagar
               por el precio de una
               residencia?”.
            </p>

            <p>
               Inforesidencias.com, portal líder en el sector de las búsqueda y comparación de residencias para mayores en
               España, que lleva quince
               años publicando periódicamente datos sobre precios de las residencias privadas en España, ha decidido crear
               el “Indicador IR” para
               comparar los precios de las residencias de tercera edad en diferentes países de una forma racional que
               permita determinar dónde son
               verdaderamente más caras en términos, no solo absolutos sino también relativos.
            </p>
            <div className="d-grid gap-2 mb-3">
            <Link  to="/app/registro">
                         <Button variant="outline-primary" size="lg">Registrate para ver todo el informe</Button>
             </Link>
            </div> 
         </Col>


   </Row>
</Container>
</Layout>
)

}

export default Ir